import { Injectable, signal } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

type hookFn = (value?: boolean) => void;

@Injectable()
export abstract class AbstractCheckboxComponent implements ControlValueAccessor {
  //@Input() color: BoxThemePalette = 'green';
  onChange: hookFn;
  onTouched: hookFn;
  disabled: boolean;
  checked = signal(false);

  //abstract changed: EventEmitter<boolean>;

  protected constructor() { }

  onCheckboxChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    this.checked.set(isChecked);
    //this.changed.emit(isChecked);

    if (this.onChange) {
      this.onChange(this.checked());
    }
  }

  writeValue(checked: boolean): void {
    this.checked.set(checked);
  }

  registerOnChange(fn: hookFn) { this.onChange = fn; }

  registerOnTouched(fn: hookFn) { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
