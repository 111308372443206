import { computed, inject, Injectable, OnInit, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { CartService, CustomerAddressService } from '@bc-core/api-services/api-us-services';
import { AddressOption } from '@bc-core/new-models/us-models';
import { Address, AddressFormService, VoucherType } from '@bc-libs/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

type AddressType = 'delivery' | 'billing';

@Injectable()
export abstract class AbstractDeliveryPageComponent implements OnInit {
  addresses: Signal<AddressOption[]>;
  isLoaded: Signal<boolean>;
  selectedAddress: Signal<AddressOption>;
  isSaving = signal(false);
  sameControl = new FormControl(true);
  editingAddress = signal<Address>(null);
  billingAddress = signal<Address>(null);
  addressFormType = signal<AddressType>(null);
  isAddressAdding = signal(false);
  isAddressesSelected: Signal<boolean>;

  #cartService = inject(CartService);
  #addressService = inject(CustomerAddressService);
  #addressFormService = inject(AddressFormService);

  protected abstract backToList(): void;

  protected abstract showVoucherErrorModal(): Observable<boolean>;

  protected abstract navigateToServices(): void;

  protected constructor() {
    this.addresses = this.#addressService.list;
    this.selectedAddress = this.#cartService.selectedDeliveryAddress;
    this.isLoaded = this.#addressService.listLoaded;

    const isSame = toSignal(this.sameControl.valueChanges, { initialValue: this.sameControl.value });

    this.isAddressesSelected = computed(() => !!this.selectedAddress() && (isSame() || !!this.billingAddress()));
  }

  ngOnInit(): void {
    // todo add method to load all
    this.#addressService.loadList({ pageSize: 1000 });
  }

  select(address: AddressOption): void {
    this.#cartService.selectedDeliveryAddress = address;
  }

  editAddress(address: AddressOption, type: AddressType): void {
    this.editingAddress.set(address);
    this.addressFormType.set(type);
  }

  openDeliveryDetails(): void {
    this.editAddress(null, 'delivery');
  }

  openBillingDetails(): void {
    this.editAddress(null, 'billing');
  }

  saveDeliveryAddress(address: AddressOption): void {
    if (!this.#addressFormService.isAddressValid(address)) {
      this.editAddress(address, 'delivery');
      return;
    }

    const result$ = address.id
      ? this.#addressService.update(address.id, address)
      : this.#addressService.create(address);

    this.isAddressAdding.set(true);

    result$
      .pipe(
        finalize(() => this.isAddressAdding.set(false)),
      )
      .subscribe(address => {
        this.#cartService.selectedDeliveryAddress = address;
        this.#addressService.updateOrAddToList({ ...address });
      });
  }

  setBillingAddress(address: Address): void {
    if (!this.#addressFormService.isAddressValid(address)) {
      this.editAddress(address, 'billing');
      return;
    }

    this.billingAddress.set(address);
  }

  onAddressSave(address: AddressOption) {
    if (this.addressFormType() === 'delivery') {
      this.saveDeliveryAddress(address);
    } else {
      this.setBillingAddress(address);
    }

    this.backToList();
  }

  editSavedAddress(address: Address, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.editAddress(address, 'delivery');
  }

  next(): void {
    const selectedAddress = this.selectedAddress();

    if (!this.isAddressesSelected()) {
      return;
    }

    const order = this.#cartService.current();

    const confirmAddress$ = order.voucher?.type === VoucherType.FreeLocalDelivery && !selectedAddress.isLocalAddress
      ? this.showVoucherErrorModal()
      : of(true);

    confirmAddress$
      .pipe(
        filter(result => !!result),
        tap(() => this.isSaving.set(true)),
        switchMap(() => forkJoin([
          this.#cartService.addDeliveryAddress(selectedAddress.id),
          this.#cartService.addBillingAddress(this.sameControl.value ? selectedAddress : this.billingAddress()),
        ])),
        finalize(() => this.isSaving.set(false)),
      )
      .subscribe(() => this.navigateToServices());
  }
}
