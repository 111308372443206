import { inject, Injectable, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService, CartService, StoreService } from '@bc-core/api-services/api-us-services';
import { Cart, CartItem, Order } from '@bc-core/new-models/us-models';
import { JwtService } from '@bc-core/new-services/common-services';
import { StoreCartService } from '@bc-core/new-services/us-services';
import { DestroyComponent } from '@bc-libs/common-components/common/destroy/destroy.component';
import { List } from 'immutable';
import { Observable, of, timer } from 'rxjs';
import { debounce, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractCartPageComponent extends DestroyComponent {
  cart: Signal<Cart>;
  order: Signal<Order>;
  isVerifying = signal(false);
  isSaving = signal(false);

  #router = inject(Router);
  #jwtService = inject(JwtService);
  #authService = inject(AuthService);
  #cartService = inject(CartService);
  #storeService = inject(StoreService);
  #storeCartService = inject(StoreCartService);

  protected abstract closeCart(): void;

  protected abstract showVoucherErrorModal(message: string): void;

  protected abstract openGuestConfirmation(): Observable<'guest' | 'sign-in'>;

  protected abstract navigateToLogin(): void;

  protected abstract initBackUrl(): void;

  protected constructor() {
    super();

    this.cart = this.#storeCartService.cart;
    this.order = this.#cartService.current;

    toObservable(this.#storeCartService.cart)
      .pipe(
        debounce(() => timer(this.isVerifying() ? 0 : 1000)),
        filter(() => !this.isSaving()),
        switchMap(() => this.verifyCart()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onItemsChange(items: List<CartItem>): void {
    this.#storeCartService.updateCart({ lineItems: items.toArray() });
  }

  onCartSubmit(): void {
    if (this.isSaving() || this.isVerifying() || !this.order().isConfirmed) {
      return;
    }

    if (this.#jwtService.hasValidToken && !this.#authService.isGuest) {
      this.saveCart();
      return;
    }

    const store = this.#storeService.current();

    if (store.checkout.allowGuests) {
      this.openGuestConfirmation()
        .pipe(
          filter(r => !!r),
        )
        .subscribe(result => {
          if (result === 'guest') {
            this.navigateToCheckout();
          } else {
            this.navigateToLogin();
          }
        });

      return;
    }

    this.navigateToLogin();
  }

  saveCart(): void {
    this.isSaving.set(true);

    this.verifyCart()
      .pipe(
        filter(order => {
          const voucherInvalid = order.voucher && !order.voucher.isValid;

          if (voucherInvalid) {
            this.showVoucherErrorModal(order.voucher.errorMessage);
          }

          this.#storeCartService.updateCart({
            voucherCode: order.voucher?.code,
          });

          return !voucherInvalid && order.isConfirmed;
        }),
        switchMap(order => this.#cartService.saveOrder(order)),
        finalize(() => this.isSaving.set(false)),
      )
      .subscribe(order => {
        this.#cartService.setCurrent(order);

        this.navigateToCheckout();
      });
  }

  navigateToCheckout(): void {
    this.#router.navigate([ 'checkout',  ...(this.order().id ? [ this.order().id ] : []) ])
      .then(() => {
        this.closeCart();
        this.initBackUrl();
      });
  }

  private verifyCart(): Observable<Order> {
    if (!this.#storeCartService.cart().lineItems.length) {
      return of(null);
    }

    this.isVerifying.set(true);

    const order$ = this.#jwtService.hasValidToken
      ? this.#cartService.verifyAndSetCart()
      : this.#storeService.verifyAndSetCart()

    return order$
      .pipe(
        tap(() => this.isVerifying.set(false)),
      );
  }
}
